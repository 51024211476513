.form-field .form-field-input.page-home-establishment-modal-criteria-readonly-input {
	border: none;
}

.establishment-modal-form-field-establishment-list {
	list-style: none;
}

.establishment-modal-form-field-establishment-list-item {
	align-items: stretch;
	border-bottom: 1px solid $color-brand;
	display: flex;
	flex-direction: row-reverse;

	* {
		cursor: pointer;
	}

	&:hover {
		background-color: $color-gray-lighter;
	}

	.establishment-modal-form-field-establishment-input {
		flex: 0;
		margin-left: 1em;
	}

	.establishment-modal-form-field-establishment-label {
		display: block;
		flex: 1;
		padding: 1em;

		.establishment-modal-form-field-establishment-label-name {
			@include typi($typi-regular);
			color: $color-brand;
			font-family: $font-sans-serif;
			letter-spacing: normal;
			font-weight: bold;
		}

		.establishment-modal-form-field-establishment-label-town {
			padding-left: 1ch;
		}
	}
}

