/**
 *
 */

.appointments-menu-items {
	list-style: none;
}

.page-appointments-main {
	margin-bottom: vr(4);
}

.page-appointments-main-button {
	text-align: center;
}


/**
 *	Mobile
 */

@media screen and (max-width: $breakpoint-mobile-width) {
	.page-appointments {
		padding: vr(2) vr(1);
	}

	.page-appointments-aside {
		margin-bottom: vr(2);
	}
}



/**
 *	Desktop
 */

@media screen and (min-width: $breakpoint-mobile-width) {
	.page-appointments {
		@include container();
	}

	.page-appointments-aside {
		@include span(4);
	}

	.page-appointments-main {
		@include span(last 8);
	}
}

.actions-container > div > .button-secondary {
	width: 100%;
	margin-bottom: vr(0.15);
}

.page-home-appointments-not-connected-items {
	padding-left: vr(1);
}

.page-home-appointments-not-connected-button {
	text-align: center;
}
