.TranslationsDisclaimer {
	margin: 0;
	padding: vr(1/4) vr(1);
	background: $color-warning;
	text-align: center;

	// puts the notice under the fixed header on small screens
	@media screen and (max-width: $breakpoint-mobile-width) {
		position: relative;
		top: vr(4);
	}
}
