/**
 *
 */

@keyframes modal-backdrop-animation {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes modal-animation {
	0% {
		transform: scale(0.8);
	}

	100% {
		transform: scale(1);
	}
}



.rea11y-Modal-backdrop {
	display: flex;
	align-items: center;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: vr(1);
	background: rgba($color-black, 0.75);
	animation-name: modal-backdrop-animation;
	animation-duration: 100ms;
	animation-timing-function: linear;
	z-index: 200;
	overflow-y: auto;
}

.rea11y-Modal {
	flex-grow: 1;
	box-shadow: vr(1/8) vr(1/8) vr(2) $color-black;
	margin: auto;
	border: 1px solid $color-gray;
	max-width: vr(30);
	background: $color-white;
	animation-name: modal-animation;
	animation-duration: 100ms;
	transform-origin: 50% 50%;
	animation-timing-function: linear;
}

.modal-header {
	@extend .entry-header;
	background: $color-brand;
}

.modal-title {
	@extend .entry-title;
}

.modal-body,
.modal .form-body {
	padding: vr(1);
}

.modal .form-field-input {
	box-shadow: none;
	border: 1px solid $color-gray-light;
}

.modal-footer,
.modal .form-footer {
	padding: vr(1/2) vr(1);
	background: $color-gray-light;
	text-align: right;

	.button {
		display: inline-block;
		margin-left: vr(1);
	}
}
