/**
 *
 */

.table {
	width: 100%;
	border-collapse: collapse;
	background: $color-white;
}

.table caption {
	@include hidden();
}

.table thead {
	background: $color-gray-light;
}

.table th {
	@include font-display();
	padding: vr(1/2);
	background: $color-gray-lighter;
	text-align: left;
	font-size: 1em;
	vertical-align: top;
}

.table td {
	border-top: 1px solid $color-gray-lighter;
	padding: vr(1/2);
	vertical-align: top;
}

.mobile-table {
	border-bottom: 3px double $color-gray-dark;
}

.mobile-table:last-child {
	border-bottom: 0;
}

.mobile-table th {
	width: 25%;
}
