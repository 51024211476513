/**
 *
 */

@mixin clearfix() {
	overflow: hidden;
	width: 100%;
}

@mixin hidden() {
	position: absolute !important;
	clip: rect(1px, 1px, 1px, 1px);
	border: none !important;
	padding: 0 !important;
	height: 1px !important;
	width: 1px !important;
	overflow: hidden;
}

@mixin hidden-text() {
	border: none;
	background-color: transparent;
	color: transparent;
	text-shadow: none;
	font: 0 / 0 a;
}

@mixin input-shadow() {
	box-shadow: vr(1/8) vr(1/8) vr(1/4) $color-shadow;
}

@mixin font-display() {
	font-family: $font-display;
	font-weight: normal;
	letter-spacing: 0.5px;
}
