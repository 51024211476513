/**
 *
 */
.Page {
	padding-bottom: vr(2);

	@media screen and (max-width: $breakpoint-mobile-width) {
		padding: vr(2) vr(1);
	}
}

.Page-header {
	padding: 0 vr(1);
}

.Page-body {
	padding: vr(1);
	background: $color-white;

	h2, h3, h4 {
		margin-bottom: vr(1);
	}

	ol, ul {
		margin-bottom: vr(1);
		padding-left: vr(1);
	}

	blockquote {
		margin: 0 0 vr(1) 0;
		padding: 0 vr(2);
		font-style: italic;
	}

	table {
		margin-bottom: vr(1);
		border-collapse: collapse;
		width: 100%;
	}

	caption {
		font-style: italic;
	}

	th, td {
		border-bottom: 1px solid $color-gray;
		text-align: left;
	}

	a.active {
		color: $color-text;
	}
}
