.MethodTypes {
	display: flex;
	align-items: center;
	@media screen and (max-width: $breakpoint-mobile-width) {
		// flex-direction: column;
	}
}

.MethodType {
	text-align: center;
	display: flex;
	p{
		margin: 0 vr(0.25);
		font-weight: bold;
		display: flex;
		align-items: center;
		@media screen and (max-width: $breakpoint-mobile-width) {
			// display: none;
			font-size: 10px;
			margin: 0 vr(0.1);
		}
	}
	@media screen and (max-width: $breakpoint-mobile-width) {
		margin: vr(0.25) 0;
	}
}

.MethodType-icon {
	display: inline-block;
	width: vr(1);
	height: vr(1);
	vertical-align: top;

	.MethodType--large & {
		width: vr(1.2);
		height: vr(1.2);
	}
}
