.a11y-menu {
    position: absolute;
    list-style: none;
    top: 5px;
    left: 5px;
    z-index: 350;

    li {
        display: inline;
        list-style: none;

        a {
            position: absolute;
            left: -999em;
            background: #fff;
            padding: 0 5px;

            &:hover,
            &:active,
            &:focus {
                left: auto;
            }
        }
    }
}

.screen-reader-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}

[aria-invalid="true"] {
  border: 2px solid $color-red;
}
