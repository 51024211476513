/**
 *	Search
 */

@keyframes result-animation {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}



.pager {
	@include clearfix();
	margin: 0 vr(1) vr(1/2) vr(1);
	width: auto;
}

.pager-action {
	position: relative;
	border: none;
	padding: 0;
	background: transparent;
	color: $color-brand;
	cursor: pointer;

	&:hover,
	&:focus {
		color: $color-brand-light;
	}
}

.pager-action-previous {
	float: left;
	margin-left: vr(1/2);
	text-align: left;

	&:before {
		content: '<';
		position: absolute;
		top: 0;
		left: vr(-1/2);
		line-height: vr(1);
		font-weight: bold;
	}
}

.pager-action-next {
	float: right;
	margin-right: vr(1/2);
	text-align: right;

	&:before {
		content: '>';
		position: absolute;
		top: 0;
		right: vr(-1/2);
		line-height: vr(1);
		font-weight: bold;
	}
}

.pager-title {
	@extend h2;
	display: block;
}

.pager-range {
	@extend h3;
	display: block;
}



.search-filters-toggle .rea11y-ToggleButton {
	@include typi($typi-regular);
	margin: 0;
	border: 0;
	padding: vr(1/2) vr(1);
	width: 100%;
	background: $color-gray-darker;
	color: $color-white;
	font-weight: bold;

	&:hover,
	&:focus {
		background: $color-gray-dark;
	}
}

.search-filters-toggle .rea11y-ToggleButton[aria-pressed="true"] {
	background: $color-gray;
}

.search-filters-form .form-fieldset .form-field-label {
	@include hidden();
}



.search-results-message {
	line-height: vr(2);
	font-style: italic;
}

.search-results .search-result:last-child {
	margin-bottom: vr(6);
}

.search-result {
	@extend .entry;
	animation-name: result-animation;
	animation-duration: 100ms;
	transform-origin: 50% 50%;
	animation-timing-function: linear;
}

.search-result-header {
	@extend .entry-header;
	display: flex;
}

.search-result-title {
	@extend .entry-title;
	flex-grow: 1;
}

.search-result-body {
	position: relative;
	background: $color-white;
}

.search-result-subtitle {
	margin: 0 vr(1) vr(1/2) vr(1);
	color: $color-text;
	line-height: vr(1);
	font-size: 1em;
	font-weight: bold;
	font-family: $font-sans-serif;
	text-transform: none;
}

.search-result-summary {
	padding: vr(2/3) vr(1);
}

.search-result-summary--info {
	display: flex;
	align-items: center;
}

.search-result-summary table {
	width: 100%;
	border-collapse: collapse;
}

.search-result-summary caption {
	@include hidden();
}

.search-result-summary thead {
	@include hidden();
}

.search-result-summary th {
	padding: 0 vr(1) vr(1/2) 0;
	color: $color-text;
	text-align: left;
}

.search-result-summary th:last-child {
	padding-right: 0;
	text-align: right;
}

.search-result-summary tr {
	@media screen and (max-width: $breakpoint-mobile-width) {
		display: flex;
		flex-wrap: wrap;
	}
}

.search-result-summary td {
	padding-right: vr(1);
	box-sizing: border-box;
	@media screen and (max-width: $breakpoint-mobile-width) {
		padding-right: vr(0.75);
	}
}


.search-result-summary td.search-result-method-type {
	@media screen and (max-width: $breakpoint-mobile-width) {
		padding-right: vr(1);
	}
	@media screen and (max-width: $breakpoint-small-mobile-width) {
		width: 50%;
	}
}

.search-result-summary td.search-result-method-duration {
	@media screen and (max-width: $breakpoint-mobile-width) {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		padding: 0;
	}
	@media screen and (max-width: $breakpoint-small-mobile-width) {
		width: 50%;
	}
}

.search-result-summary .search-result-next-appointment {
	padding-right: 0;
	text-align: right;
	@media screen and (max-width: $breakpoint-mobile-width) {
		padding-top: vr(0.5);
		width: 100%;
		display: flex;
		justify-content: flex-start;
		text-align: left;
	}
}


.search-result-icon {
	display: inline-block;
	width: vr(1);
	height: vr(1);
	vertical-align: top;
	margin-right: vr(1);
}

.search-result-info-title,
.search-result-method-duration {
	font-weight: bold;
	white-space: nowrap;
}

.search-result-info-title {
	margin-top: 2px;
}

.search-result-next-appointment {
	width: 80%;
	text-align: right;

	p {
		margin-bottom: 0;
	}
}

.search-result-detail-toggle {
	@extend .button;
	@extend .button-large;
	@extend .button-primary;
	width: 100%;
}

.search-result-description {
	padding: 0 vr(1);
}

.search-result-closed {
	.search-result-description {
    display: -webkit-box;
		-webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
		overflow: hidden;
	}

	.search-result-allocations {
		@include hidden();
	}
}

.search-result-disabled {
	color: $color-gray;

	.search-result-header {
		background-color: lighten($color-gray, 15%);
	}
	.search-result-detail-toggle {
		cursor: pointer;
		color: $color-gray-dark;
		background-color: darken($color-gray-light, 10%);

		&:hover,
		&:focus {
			background-color: darken($color-gray-light, 5%);
		}
	}
}

.SearchResult-chatButton {
	float: right;
}



.allocations-calendar-week {
	@include container(7);
}

.allocations-calendar-day {
	@include span(1 of 7 0);
	box-sizing: border-box;
	border-left: 1px solid $color-white;
}

.allocations-calendar-day:last-child {
	@include last();
	border-right: 1px solid $color-white;
}

.allocations-calendar-title {
	@include font-display();
	padding: vr(1/4);
	background: $color-gray-lighter;
	text-align: center;
	font-size: 1em;
}

.allocations-calendar-title span {
	display: block;
}

.allocations-calendar-message {
	text-align: center;
	font-weight: normal;
}

.allocations-calendar-list {
	padding: vr(1/2);
	list-style: none;
	text-align: center;
}

.allocations-calendar-slot {
	border: none;
	background: none;
	margin: 0;
	padding: 0;
	line-height: vr(1);
	text-decoration: underline;
	cursor: pointer;

	&:hover,
	&:focus {
		color: $color-brand-light;
	}
}

.page-search-summary-search {
	margin-bottom: 0;
}

.page-search-summary-backLink {
	text-align: right;
	margin-bottom: vr(1/2);

	a {
		color: $color-magenta;
	}
}

.page-search-summary-warning {
	padding: 0.5rem 0.5rem 0.5rem 4rem;
	margin-bottom: 1.5rem;
	border: 1px solid black;
	background: url('../img/warning.svg') no-repeat 1rem 50%/2rem;

	p {
		margin: 0;
	}
}


/**
 *	Mobile
 */

@media screen and (max-width: $breakpoint-mobile-width) {
	.page-search {
		padding: vr(2) vr(1);
	}

	.search-filters-form .form-legend,
	.search-filters-form .form-field-label {
		color: $color-brand-light;
	}

	.search-filters-form .form-field-input,
	.search-filters-form .form-button {
		box-shadow: none;
	}

	.search-filters-toggle .rea11y-ToggleButton {
		position: fixed;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 80;
	}

	.search-filters-form {
		box-sizing: border-box;
		position: fixed;
		right: 0;
		bottom: -75vh;
		left: 0;
		border-top: 1px solid $color-gray;
		padding: vr(1) vr(1) vr(3) vr(1);
		max-height: 75vh;
		overflow: auto;
		background: $color-gray-dark;
		z-index: 70;
		transition: bottom ease-in 100ms;

		.search-filters-open & {
			bottom: 0;
		}
	}
}



/**
 *	Desktop
 */

@media screen and (min-width: $breakpoint-mobile-width) {
	.route-search .page-background-split {
		background: $color-gray-lighter;

		&:before {
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			width: 37.5%;
			background: $color-gray-light;
		}
	}



	.page-search {
		@include container();
	}

	.page-search-aside {
		@include span(4);
		@include post(1);
	}

	.page-search-main {
		@include span(last 7);
	}

	.search-filters-toggle .rea11y-ToggleButton {
		display: none;
	}
}
