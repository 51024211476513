/**
 *
 */

html,
body,
ul,
li {
	margin: 0;
	padding: 0;
}

button {
	cursor: pointer;
}

a {
	color: $color-text;
	text-decoration: underline;

	&:hover,
	&:focus {
		color: $color-brand-light;
	}

	&:active,
	&.active {
		color: $color-brand;
	}
}
