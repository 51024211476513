/**
 *
 */

.entry {
	@include input-shadow();
	margin-bottom: vr(2);
}

.entry-header {
	padding: vr(1/2) vr(1);
	background: $color-gray-dark;
	color: $color-white;
}

.entry-title {
	@include typi($typi-h3);
	margin: 0;
}
