/**
 *	Home
 */

.page-home-section-appointments .page-home-section-header {
	margin-bottom: vr(2);
}

.page-home-appointments-items {
	list-style: none;
}

.page-home-appointment {
	@include clearfix();
	margin-bottom: vr(1);
}

.page-home-appointment-method {
	float: left;
	padding-right: vr(0.5);
}

.page-home-appointment-date {
	margin: 0 0 0 vr(3);
}

.page-home-appointment-subject {
	margin: 0 0 0 vr(3);
	font-style: italic;
}

.page-home-search-infos {
	margin: 0;
}

.page-home-establishment-modal-button {
	margin-bottom: vr(1);
}

.page-home-establishment-modal-criteria-readonly-input {
	border: none;
}

/**
 *	Mobile
 */

@media screen and (max-width: $breakpoint-mobile-width) {
	.page-home-section {
		padding: vr(2) vr(1);
	}

	.page-home-section-search {
		background:
			$color-gray-light
			url('../img/background-calendar.png')
			no-repeat
			center vr(2);
	}

	.page-home-section-appointments {
		background:
			$color-gray-lighter
			url('../img/background-text.png')
			no-repeat
			center vr(2);
	}
}



/**
 *	Desktop
 */

@media screen and (min-width: $breakpoint-mobile-width) {
	.page-home {
		@include container();
		border: 2px solid $color-white;
		padding: vr(4) 0;
	}

	.page-home-section {
		@include push(1);
		@include span(4);
		@include post(1);
	}
}
