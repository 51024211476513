/**
 *
 */

.flash {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 200;
}
