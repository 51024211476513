/**
 *
 */

.Attachments-addButton {
	@extend .button;
	@extend .button-secondary;
	margin-bottom: vr(1);
}

.Attachments-item {
	display: flex;
	align-items: center;
	margin-bottom: vr(1);

	label {
		@include hidden();
	}

	input {
		margin-bottom: 0;
	}

	> :first-child {
		flex-grow: 1;
		margin-right: vr(1);
	}
}

.Attachments-removeButton {
	border: none;
	border-radius: 50%;
	width: vr(1);
	height: vr(1);
	background: $color-gray-dark;
	color: $color-white;
	text-align: center;
	font-weight: bold;

	&:hover,
	&:focus {
		background: $color-red;
	}
}
