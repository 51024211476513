.CookieWarning {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	padding: vr(0.25);
	text-align: center;
	background: rgba($color-black, 0.6);
	color: $color-white;
	z-index: 200;
}

.CookieWarning {
	color: $color-white;
}

.CookieWarning-text {
	display: inline;
	margin-right: vr(1/2);
}
