/**
 *
 */

.vtChat-Button {
	@extend .button;
}

.vtChat-Form-submit {
	@extend .button-primary;
}

.vtChat-Form-input {
	@extend .form-field-input;
}

.vtChat-Chat-body {
	display: flex;
}

.vtChat-Chat-discussionCards {
	flex-grow: 1;
}

.vtChat-Chat-discussion {
	flex-grow: 2;
}

.vtChat-DiscussionCardList-list {
	list-style: none;
	padding: 0;
}

.vtChat-DiscussionCardList,
.vtChat-Discussion {
	padding: vr(1/3) 0;
	margin: vr(1/3) 0;
}

.vtChat-Discussion-title {
	margin-bottom: vr(1);
}

.vtChat-MessageList {
	margin: 0;
	border-bottom: 1px solid $color-brand;
	height: vr(10);
	padding: vr(1/2);
	background: $color-white;
	list-style: none;
	overflow-y: auto;
}

.vtChat-MessageList--disabled {
	background: $color-gray-lighter;
}

.vtChat-MessageForm {
	display: flex;

	.vtChat-Form-input {
		flex-grow: 1;
		margin: 0;
	}
}
