.Breadcrumbs {
	@include typi($typi-small);
	@include span(last 12);
	box-sizing: border-box;
	margin-top: vr(1);

	p {
		margin: 0;
	}

	@media screen and (max-width: $breakpoint-mobile-width) {
		margin-top: 0;
	}
}

.Breadcrumbs-label,
.Breadcrumbs-itemList,
.Breadcrumbs-item {
	display: inline-block;
}

.Breadcrumbs-item:not(:last-child):after {
	content: '\00a0\003e\00a0';
}
