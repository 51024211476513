.LocaleMenu {
	position: relative;
	padding-left: vr(1);

	&:before {
		position: absolute;
		top: 0;
		left: 0;
		float: left;
		padding-right: 1em;
		width: 1em;
		height: vr(1);
		background-image: url('../img/intl.svg');
		background-size: 1em 1em;
		background-position: left center;
		background-repeat: no-repeat;
		content: '';
	}
}

.LocaleMenu-item {
	display: inline-block;
	margin-right: vr(1/4);
	vertical-align: text-top;
}

.LocaleMenu-flag {
	height: 1em;
}

@media screen and (max-width: $breakpoint-mobile-width) {
	.LocaleMenu {
		display: block;
		border-bottom: 1px solid $color-gray;
		padding: vr(1/2) vr(1) vr(1/2) vr(2);

		&:before {
			padding: 0 1em;
			height: vr(2);
			background-position: vr(1) center;
		}
	}
}
