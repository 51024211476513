.chat-panel {
    background-color: white;
}

.chat-panel-button-container .rea11y-ToggleButton {
	@include typi($typi-h3);
	@include font-display();
	position: fixed;
	bottom: 0;
	right: 0;
	margin: 0;
	border: 0;
	padding: vr(1/2) vr(1);
	width: 15%;
	z-index: 60;
	background: $color-brand;
	color: $color-white;

	&:hover,
	&:focus {
		background: $color-brand;
	}
}

.chat-panel-button-container .rea11y-ToggleButton[aria-pressed="true"] {
	background: $color-brand;
}

.chat-panel-subjects-container {
	box-sizing: border-box;
	position: fixed;
	right: 0;
	bottom: -75vh;
	padding: vr(3/4) vr(1/2) vr(3) vr(1/2);
	max-height: 75vh;
	width: 15%;
	overflow: auto;
	background: $color-white;
	z-index: 50;
	box-shadow: -0.1875rem 0 vr(1/4) $color-shadow;
	transition: bottom ease-in 100ms;


	.chat-panel-open & {
		bottom: 0;
	}
}



.chat-panel-subjects-list {
	margin-left: vr(1);
}

.chat-panel-subjects-title {
	text-align: center;
	font-size: vr(3/4);
	font-weight: 600;
}


.chat-panel-subject-link:focus ,
.chat-panel-subject-link:hover {
	text-decoration: none;
	color: $color-text;
}

/**
 *	Mobile
 */
@media screen and (max-width: $breakpoint-mobile-width) {
	.chat-panel-button-container .rea11y-ToggleButton {
		@include typi($typi-regular);
		position: fixed;
		right: 0;
		bottom: 2.9rem;
		left: 0;
		z-index: 60;
		width: 100%;
		font-family: $font-sans-serif;
		letter-spacing: 0;
		font-weight: bold;
	}

	.chat-panel-subjects-container {
		width: 100%;

		.chat-panel-open & {
			bottom: vr(2);
		}
	}
}

/**
 * Tablet
 */
@media screen and (min-width: $breakpoint-mobile-width) and (max-width: $breakpoint-tablet-width) {
	.chat-panel-button-container .rea11y-ToggleButton,
	.chat-panel-subjects-container {
		width: 25%;
	}
}
