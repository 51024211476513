.page-fos {
	position: relative;
	height: 100vh;
	overflow-y: hidden;
}

.resetting-password-container {
	margin-top: 15rem;
	margin-left: 30rem;
	margin-right: 30rem;
}

.resetting-password-content {
	padding: 1rem;
	background-color: white;
}

.resetting-password-input {
	box-sizing: border-box;
    margin-bottom: 1.5rem;
    padding: 0.75rem;
    width: 100%;
    color: $color-text;
}

.resetting-password-button {
	@extend .button;
	@extend .button-primary;
	padding: 0.5rem;
}
