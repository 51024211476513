/**
 *	Icons
 */

@font-face {
	font-family: 'rdv';
	src: url('../fonts/rdv.eot?tae1kc');
	src:
		url('../fonts/rdv.eot?tae1kc#iefix') format('embedded-opentype'),
		url('../fonts/rdv.ttf?tae1kc') format('truetype'),
		url('../fonts/rdv.woff?tae1kc') format('woff'),
		url('../fonts/rdv.svg?tae1kc#rdv') format('svg');
	font-weight: normal;
	font-style: normal;
}

.icon {
	font-family: 'rdv';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon span {
	@include hidden-text();
}

.icon-method-phone:before {
	content: '\e900';
}

.icon-method-face-to-face:before {
	content: '\e901';
}

.icon-method-skype:before {
	content: '\e902';
}

.method-icon {
	height: vr(2);
	vertical-align: super;
}
