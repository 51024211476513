/**
 *
 */

.loadable {
	position: relative;
}

.loadable-loading {
	opacity: 0.5;
	cursor: wait;
}

.loadable-loading:after {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}
