/**
 *	Typography
 */

$font-size: 15px;
$line-height: 1.5;

$typi: (
	null: ($font-size, $line-height)
);

$typi-brand: (
	null: (3em, 1)
);

$typi-h1: (
	null: (2em, 2/3)
);

$typi-h2: (
	null: (1.5em, 1)
);

$typi-h3: (
	null: (1.2em, 1.25)
);

$typi-label: (
	null: (1.2em, 2.5)
);

$typi-regular: (
	null: (1em, 1.5)
);

$typi-narrow: (
	null: (1em, 1.3)
);

$typi-small: (
	null: ($font-size * (13/15), $line-height / (13/15))
);

$font-display: bebas-neue, 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-sans-serif: 'Helvetica Neue', Helvetica, Arial, sans-serif;



/**
 *	Grid
 */

$susy: (
	columns: 12,
	gutters: 1 / 4,
	math: fluid
);



/**
 *	Colors
 */

$color-black: #000;
$color-gray-darker: #2c2829;
$color-gray-dark: #312d2e;
$color-gray: #464243;
$color-gray-light: #e6e6e6;
$color-gray-lighter: #ececec;
$color-white: #fff;

$color-shadow: #cfcfcf;

$color-green: #c8ce4d;
$color-orange: #f9862b; // #e46806
$color-red: #e40613;
$color-magenta: #c11f39;

$color-text: #343434;
$color-text-light: $color-white;
$color-brand: $color-magenta;
$color-brand-light: $color-red;
$color-success: $color-green;
$color-warning: $color-orange;
$color-error: $color-red;



/**
 *
 */
$breakpoint-small-mobile-width: vr(20);
$breakpoint-mobile-width: vr(35);
$breakpoint-mobile-height: vr(15);
$breakpoint-tablet-width: vr(65);
