/**
 *	Forms
 */

.form-fieldset {
	margin: 0;
	border: 0;
	padding: 0;
	border: none;
}

.form-legend,
.form-field-label,
.form-infos-documents-title {
	@include typi($typi-label);
	display: block;
	margin: 0;
	padding: 0;
	color: $color-brand;
	font-family: $font-sans-serif;
	letter-spacing: normal;
	font-weight: bold;
}

.form-field-hint {
	margin-bottom: vr(1/2);
	color: $color-gray;
	font-style: italic;
}

.form-field-error {
	margin: 0;
	color: $color-red;
}

.form-field-description {
	@include typi($typi-narrow);
}

.form-field-input {
	@include input-shadow();
	box-sizing: border-box;
	margin-bottom: vr(1);
	border: none;
	border-radius: 3px;
	padding: vr(0.5);
	width: 100%;
	background: $color-white;
	color: $color-text;
	font-family: $font-sans-serif;

	&[disabled] {
		background: $color-gray-lighter;
	}
}

.form-autocomplete {
	position: relative;
}

.form-autocomplete-suggestions {
	list-style-type: none;
	@include typi($typi-small);
	box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1), 0 3px 3px rgba(0, 0, 0, 0.05);
	border-radius: 3px;
	position: absolute;
	width: 100%;
	margin-top: calc(#{vr(1) * -1} + 5px);
	top: 100%;
	z-index: 10;
	background: white;
	max-height: vr(10.75);
	overflow: auto;
}

.form-autocomplete--above .form-autocomplete-suggestions {
	top: auto;
	margin-top: 0;
	margin-bottom: 5px;
	bottom: 100%;
}

.form-autocomplete-suggestion-item {
	padding: vr(1/8) vr(1/2);
	border-top: 1px solid transparent;
	border-bottom: 1px solid transparent;
}

.form-autocomplete-suggestion-item:first-child {
	padding-top: vr(1/4);
}

.form-autocomplete-suggestion-item:last-child {
	padding-bottom: vr(1/4);
}

.form-autocomplete-suggestion-item--active {
	border-top-color: rgba($color-red, 0.2);
	background: rgba($color-red, 0.05);
	border-bottom-color: rgba($color-red, 0.2);
}

.form-button {
	@include input-shadow();
	width: 100%;
}

.form-infos-documents-list {
	margin-left: vr(1);
}

.form-contact-details {
	border: 2px solid #c11f39;
	padding: 1rem;
}

.form-checkbox-contact {
	text-align: left;
	margin-bottom: 1rem;
	display: flex;
    align-items: flex-start;

	.checkbox-contact {
		margin-right: 0.5rem;
	}
}

.form-button-footer {
	display: flex;
	justify-content: space-between;
}

.form-field-title {
	line-height: 1.5;
}

.form-create-appointment p, .form-create-appointment fieldset{
	margin-bottom: 0.5rem;
}

.form-procedure {
	max-height: 10rem;
	overflow: auto;
}

#form-field-organism-label {
	line-height: 1.5;
	padding: 0.5em 0;
}
