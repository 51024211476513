/**
 *
 */

.FlashMessage {
	padding: vr(1/2);
	background: $color-white;
	text-align: center;
	font-weight: bold;
}

.FlashMessage--success {
	background: $color-success;
}

.FlashMessage--warning {
	background: $color-warning;
}

.FlashMessage--error {
	background: $color-error;
	color: $color-white;
}

.FlashMessage-closeButton {
	@include typi($typi-regular);
	@include font-display();
	display: inline;
	margin-left: 2rem;
	border: 2px solid;
	border-radius: 3px;
	background-color: transparent;
	color: $color-text;
	border-color: $color-text;

	.FlashMessage--error & {
		color: $color-white;
		border-color: $color-white;
	}
}
