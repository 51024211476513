/**
 *
 */

.button {
	@include typi($typi-regular);
	@include font-display();
	box-sizing: border-box;
	border: none;
	padding: 0 vr(0.5);
	text-decoration: none;
	cursor: pointer;
}

.button-large {
	@include typi($typi-h3);
	padding: vr(0.5) vr(1);
}

.button-secondary {
	background: $color-gray-light;
	color: $color-text;

	&:hover,
	&:focus {
		color: $color-text;
		background: $color-gray-lighter;
	}
}

.button-primary {
	background: $color-brand;
	color: $color-text-light;

	&:hover,
	&:focus {
		color: $color-text-light;
		background: $color-brand-light;
	}
}

.button[disabled]{
	width: 100%;
	cursor: default;
}

.link-button {
	display: block;

	&:hover {
		color: $color-text-light;
	}

	&:focus-visible {
		outline: auto;
	}
}
