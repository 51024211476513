/**
 *
 */

html {
	@include typi-base();
	color: $color-text;
	font-family: $font-sans-serif;
	background: $color-gray-lighter;
}

body {
	min-height: 100vh;
	background-color: $color-gray;
}

.page {
	position: relative;
	overflow-x: hidden;
	min-height: 100vh;
}

.page-in-iframe {
	.page-menu-item:first-child,
	.Breadcrumbs,
	.page-footer {
		display: none;
	}
}

.page-inner {
	margin: 0 auto;
	max-width: vr(52);
}




/**
 *	Header
 */

.page-header {
	@include container();

	a {
		text-decoration: none;
	}
}

.page-brand {
	@include typi($typi-brand);
	color: $color-brand;
	font-family: $font-display;

	&:before {
		display: block;
		float: left;
		padding-right: vr(1/2);
		width: vr(2);
		height: vr(2);
		background:
			url('../img/logo.png')
			top left
			no-repeat;
		background-size: vr(2);
		content: '';
	}

	&:hover,
	&:focus {
		color: $color-brand-light;
	}

	@media screen and (max-width: $breakpoint-mobile-width) {
		font-size: vr(4/3);

		&:before {
			background-size: vr(4/3);
			width: vr(4/3);
			height: vr(4/3);
		}
	}
}

.page-menu-item abbr {
	text-decoration: none;
}

.user-menu-account,
.user-menu-logout,
.user-menu-login {
	display: block;
	position: relative;
	padding-left: vr(1);
}

.user-menu-login {
	word-break: break-word;
}

.user-menu-account {
	margin-bottom: 0;
}

.user-menu-account:before,
.user-menu-logout:before,
.user-menu-login:before {
	position: absolute;
	top: 0;
	left: 0;
	float: left;
	padding-right: 1em;
	width: 1em;
	height: vr(1);
	background-size: 1em 1em;
	background-position: left center;
	background-repeat: no-repeat;
	content: '';
}

.user-menu-account:before {
	background-image: url('../img/account.svg');
}

.user-menu-logout:before,
.user-menu-login:before {
	background-image: url('../img/logout.svg');
}



/*  =============================================
	Footer
	============================================= */

.page-footer {
	min-height: vr(3);
	box-sizing: border-box;
	background-color: $color-gray;
	color: $color-text-light;

	a {
		color: $color-text-light;
	}
}

.page-footer-inner {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin: 0 auto;
	max-width: vr(50);
}

.page-footer-links {
	padding: vr(1);
	display: inline-block;
	list-style-type: none;

	li {
		display: inline-block;
		padding-right: vr(1);
	}
}

.page-footer-a11y {
	display: flex;
	align-items: center;
	margin: 0;
}

/**
 *	Mobile
 */

@media screen and (max-width: $breakpoint-mobile-width) {
	.page-inner {
		background: $color-gray-light;
	}

	.page-content-wrapper {
		margin-bottom: 0;
	}

	.page-header {
		position: fixed;
		top: 0;
		right: 0;
		left: 0;
		padding: vr(1/2) vr(1);
		background: $color-white;
		box-shadow: vr(1/8) 0 vr(1/4) $color-shadow;
		z-index: 100;
	}

	.page-branding {
		@include span(10);
		margin-top: 0.35rem;
	}

	.page-menus-toggle {
		@include span(last 2);
		text-align: right;
	}

	.page-menus-toggle-button {
		@include hidden-text();
		display: inline-block;
		margin: 0;
		border: none;
		padding: 0;
		background: transparent;
		width: vr(2);
		height: vr(2);
		background-size: 100%;
		background-position: top left;
		background-repeat: no-repeat;
		background-image: url('../img/menu-open.svg');
	}

	.page-menus-open .page-menus-toggle-button {
		background-image: url('../img/menu-close.svg');
	}

	.page-menus {
		display: none;
		position: fixed;
		top: vr(8/3);
		left: 0;
		bottom: 0;
		border-left: 1px solid $color-gray;
		width: 100vw;
		background: $color-gray-dark;
		z-index: 100;
	}

	.page-menus-open .page-menus {
		display: block;
	}

	.page-menu-item {
		display: block;
		border-bottom: 1px solid $color-gray;

		a {
			display: block;
			padding: vr(1/2) vr(1);
			color: $color-text-light;
			font-weight: bold;
		}

		a:hover,
		a:focus {
			background: $color-gray;
		}
	}

	.user-menu-item {
		display: block;
		border-bottom: 1px solid $color-gray-dark;
		background: $color-gray-darker;

		a {
			display: block;
			padding: vr(1/2) vr(1) vr(1/2) vr(2);
			color: $color-text-light;
			font-weight: bold;
		}

		a:hover,
		a:focus {
			background: $color-gray;
		}
	}

	.user-menu-account {
		display: block;
		margin-bottom: 0;
		padding: vr(1/2) vr(1) vr(1/2) vr(2);
		color: $color-text-light;
		font-weight: bold;
	}

	.user-menu-login:before,
	.user-menu-account:before,
	.user-menu-logout:before {
		padding: 0 1em;
		width: 1em;
		height: vr(2);
		background-position: vr(1) center;
	}

	.page-main {
		padding-top: vr(4);
	}
}

@media
	screen
	and (max-width: $breakpoint-mobile-width)
	and (min-height: $breakpoint-mobile-height) {

	.secondary-menu {
		position: absolute;
		right: 0;
		bottom: 0;
		left: 0;
	}
}



/**
 *	Desktop
 */

@media screen and (min-width: $breakpoint-mobile-width) {
	.page-inner {
		padding: 0 vr(1);
	}

	.page-content-wrapper {
		margin-bottom: vr(3);
	}

	.page-backgrounds {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background:
			$color-gray-lighter
			url('../img/background.png')
			repeat-y center;
	}

	.page-background-split {
		position: relative;
		margin: 0 auto;
		max-width: vr(45);
		min-height: 100vh;
	}

	.page-background-calendar {
		position: absolute;
		top: vr(22);
		left: vr(-2.5);
		width: 464px;
		height: 367px;
		content: '';
		background:
			url('../img/background-calendar.png')
			no-repeat;
	}

	.page-background-text {
		position: absolute;
		top: vr(6);
		right: vr(-2.5);
		width: 377px;
		height: 571px;
		content: '';
		background:
			url('../img/background-text.png')
			no-repeat;
	}

	.page-header {
		padding-top: vr(2);
		padding-bottom: vr(2);
	}

	.page-branding {
		@include span(4);
	}

	.page-menus-toggle {
		display: none;
	}

	.page-menus {
		@include span(last 8);
	}

	.page-menu {
		@include span(6 of 8);
	}

	.page-menu-item {
		display: inline-block;
		margin-right: vr(1);

		a {
			position: relative;
			display: block;
			color: $color-text;
			text-transform: uppercase;
			line-height: vr(2);
			font-weight: bold;
		}

		.active:after {
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			bottom: -2px;
			height: 2px;
			background: $color-brand;
		}

		a:hover,
		a:focus {
			color: $color-brand-light;
		}
	}

	.secondary-menu {
		@include span(last 2 of 8);
	}

	.user-menu-items {
		list-style: none;
	}

	.user-menu-item {
		line-height: vr(1);
		font-weight: bold;

		a {
			color: $color-text;
		}

		a:hover,
		a:focus {
			color: $color-brand-light;
		}
	}

	.page-footer {
		position: absolute;
		right: 0;
		bottom: 0;
		left: 0;
	}
}
