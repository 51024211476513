/**
 *	Typography
 */

@font-face {
	font-family: 'bebas-neue';
	src: url('../fonts/bebasneue.eot');
	src:
		url('../fonts/bebasneue.eot?#iefix') format('embedded-opentype'),
		url('../fonts/bebasneue.woff2') format('woff2'),
		url('../fonts/bebasneue.woff') format('woff'),
		url('../fonts/bebasneue.ttf') format('truetype'),
		url('../fonts/bebasneue.svg#bebas-neue') format('svg');
	font-weight: normal;
	font-style: normal;
}



h1,
h2,
h3,
h4,
h5,
h6 {
	@include font-display();
	margin: 0;
	padding: 0;
}

h1 {
	@include typi($typi-h1);
	margin-bottom: vr(1);
}

h2 {
	@include typi($typi-h2);
}

h3 {
	@include typi($typi-h3);
}

p {
	margin: 0 0 vr(1);
}

.reset-title-styles {
	@include typi-base();
	font-family: $font-sans-serif;
	letter-spacing: normal;
}
