.MethodTypeList {
	display: flex;
	margin: 0 auto;
	max-width: vr(15);
	justify-content: space-around;

	.MethodType {
		padding: vr(1/4);
	}

	.MethodType-icon {
		width: vr(1.5);
		height: vr(1.5);
	}
}
